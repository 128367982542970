/*eslint-disable*/
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const AverageSecureScoreAreaChart = averageSecureScore => {
  const scores = averageSecureScore.averageSecureScore
  return (
    <div className='flex items-center justify-center h-[100%]'>
      <ResponsiveContainer width='100%' height={250}>
        <AreaChart data={scores}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='day'
            label={{
              value: 'Days Ago',
              position: 'insideBottomLeft',
              dy: 20,
            }}
          />
          <YAxis
            dataKey='score'
            label={{
              value: 'Average Secure Score',
              angle: -90,
              dx: 5,
              dy: 90,
              position: 'insideLeft',
            }}
          />
          <Tooltip
            formatter={(value, name) => [`${value}%`, ' Average Secure Score']}
            labelFormatter={label => `${label} Days Ago`}
          />
          <Legend />
          <Area
            type='monotone'
            dataKey='score'
            name='Average Secure Score Timeline'
            stroke='#178BDB'
            fill='#178BDB'
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default AverageSecureScoreAreaChart

export const GreenBadge = () => (
  <span className='flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm bg-emerald-100 text-emerald-700'>
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='far'
      data-icon='clock'
      className='svg-inline--fa fa-clock mr-1'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z'
      />
    </svg>
    <p className='whitespace-nowrap text-sm'>inforcer Ready</p>
  </span>
)

export const YellowBadge = ({ missingRoles }) => (
  <div className='inline-flex items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='-ms-1 me-1.5 size-4'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
      />
    </svg>
    <p className='whitespace-nowrap text-sm'>Missing {missingRoles} Roles</p>
  </div>
)

export const RedBadge = () => (
  <div className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='-ms-1 me-1.5 size-4'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
      />
    </svg>
    <p className='whitespace-nowrap text-sm'>No Relationship</p>
  </div>
)

export const TriangleExclamation = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='-pr-4 size-4'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
    />
  </svg>
)

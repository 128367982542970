const CreateTableColumns = ({
  handleSelectedCustomer,
  calculateDaysRemaining,
  formatAutoRenewalStatus,
  licenseDataExists,
}) => {
  const columns = [
    {
      header: 'Tenant Name',
      accessor: 'companyProfile.companyName',
      Cell: ({ row }) => (
        <a
          onClick={() => handleSelectedCustomer(row.original)}
          className='font-semibold cursor-pointer text-inforcer-navy'
        >
          <span className='whitespace-nowrap'>
            {row.original.companyProfile.companyName}
          </span>
        </a>
      ),
    },
    {
      header: 'GDAP Status',
      accessor: 'gdapStatus',
      Cell: ({ row }) => {
        const { unifiedRoles, readable, missingRoles } = row.original
        let gdapStatus = 'No Relationship'
        if (unifiedRoles?.length > 0) {
          if (missingRoles?.length === 0) {
            gdapStatus = 'inforcer Ready'
          } else {
            gdapStatus = `Missing ${missingRoles?.length} Roles`
          }
        }
        return gdapStatus === 'inforcer Ready' ? (
          <span className='flex w-fit items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>inforcer Ready</p>
          </span>
        ) : gdapStatus === 'No Relationship' ? (
          <span className='flex w-fit items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>No Relationship</p>
          </span>
        ) : (
          <span className='flex w-fit items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>
              Missing {missingRoles?.length} Roles
            </p>
          </span>
        )
      },
      sortingFn: (rowA, rowB) => {
        const getStatus = row => {
          const { unifiedRoles, missingRoles } = row.original
          if (unifiedRoles?.length > 0) {
            return missingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${missingRoles?.length} Roles`
          }
          return 'No Relationship'
        }

        const valueA = getStatus(rowA)
        const valueB = getStatus(rowB)

        const order = ['No Relationship', 'Missing Roles', 'inforcer Ready']
        return order.indexOf(valueA) - order.indexOf(valueB)
      },
      filterFn: (row, id, filterValue) => {
        const { unifiedRoles, missingRoles } = row.original
        let gdapStatus = 'No Relationship'

        if (unifiedRoles?.length > 0) {
          gdapStatus =
            missingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${missingRoles?.length} Roles`
        }

        return gdapStatus.toLowerCase().includes(filterValue.toLowerCase())
      },
      enableSorting: true,
      enableColumnFilter: true,
    },
    {
      header: 'Secure Score',
      accessor: 'companyProfile.secureScorePercentage',
      Cell: ({ row }) => {
        const secureScore = row.original.secureScorePercentage
        // If the value is valid, render as percentage; otherwise, show "N/A"
        if (secureScore && !Number.isNaN(secureScore)) {
          return <span className='whitespace-nowrap'>{secureScore}%</span>
        }
        return <span className='whitespace-nowrap'>N/A</span>
      },
      sortingFn: (rowA, rowB) => {
        // Parse to numbers safely, ensuring "N/A" or undefined are treated as 0
        const scoreA = parseFloat(rowA.original.secureScorePercentage) || 0
        const scoreB = parseFloat(rowB.original.secureScorePercentage) || 0
        return scoreA - scoreB
      },
      filterFn: (row, id, filterValue) => {
        const score = parseFloat(row.original.secureScorePercentage) || 0
        // Filter based on the value, ensuring we deal with numbers
        return score.toString().includes(filterValue)
      },
      enableSorting: true,
      enableColumnFilter: true,
    },

    {
      header: 'Auto Renewal',
      accessor: 'autoExtendDuration',
      Cell: ({ row }) =>
        formatAutoRenewalStatus(
          row.original.autoExtendDuration,
          row.original.id
        ),
      filterFn: (row, id, filterValue) => {
        const formattedValue = formatAutoRenewalStatus(
          row.original.autoExtendDuration,
          row.original.id,
          true
        ) // Pass asText=true
        return formattedValue.toLowerCase().includes(filterValue.toLowerCase())
      },
      enableColumnFilter: true,
    },
  ]

  if (licenseDataExists) {
    columns.splice(4, 0, {
      header: 'Primary License',
      accessor: 'primaryLicense',
      Cell: ({ row }) => {
        const license = row.original.primaryLicense || 'N/A'
        return <span className='whitespace-nowrap'>{license}</span>
      },
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.original.primaryLicense || ''
        const valueB = rowB.original.primaryLicense || ''
        return valueA.localeCompare(valueB) // String sorting
      },
      enableSorting: true, // Sorting enabled
      filterFn: (row, columnId, filterValue) => {
        const license = row.original.primaryLicense || '' // Safely access the value
        return license.toLowerCase().includes(filterValue.toLowerCase()) // Case-insensitive match
      },
      enableColumnFilter: true,
    })
  }

  return columns
}

export default CreateTableColumns

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'

const LicenseBarChart = ({
  customerCount,
  tenantsWithBusinessStandard,
  tenantsWithBusinessPremium,
  tenantsWithBusinessE3,
  tenantsWithBusinessE5,
}) => {
  const ensureVisible = value => (value === 0 ? 0.1 : value)

  const data = [
    {
      name: "Bus' Standard",
      Licenses: ensureVisible(tenantsWithBusinessStandard),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessStandard),
    },
    {
      name: " Bus' Premium",
      Licenses: ensureVisible(tenantsWithBusinessPremium),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessPremium),
    },
    {
      name: 'Business E3',
      Licenses: ensureVisible(tenantsWithBusinessE3),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessE3),
    },
    {
      name: 'Business E5',
      Licenses: ensureVisible(tenantsWithBusinessE5),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessE5),
    },
  ]

  return (
    <ResponsiveContainer width='100%' height={280}>
      <BarChart data={data}>
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='Licenses' barSize={30} stackId='a' fill='#171B3A' />
        <Bar dataKey='Remaining' barSize={30} stackId='a' fill='#a1a1aa' />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default LicenseBarChart

import { array, arrayOf, number, shape, string, bool } from 'prop-types'
import { PieChart, Pie, Cell, Tooltip, Label, Legend } from 'recharts'

const GenericPieChart = ({
  colors,
  data,
  dataLabel,
  displayValue,
  chartHeight,
  chartWidth,
  isPercentage,
  showLegend,
}) => {
  const renderCustomizedLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox

    return (
      <g>
        <text
          x={cx}
          y={cy + 8}
          textAnchor='middle'
          fontSize='48px'
          fontWeight='bold'
          fill='#171B3A'
        >
          {displayValue}
          {isPercentage && '%'}
        </text>
        <text
          x={cx}
          y={cy + 30}
          textAnchor='middle'
          fontSize='16px'
          fill='#6b7280'
        >
          {dataLabel}
        </text>
      </g>
    )
  }

  const renderLegendText = (value, entry) => {
    const { value: count } = entry.payload
    return `${value}: ${count}`
  }

  return (
    <div className='flex items-center justify-center'>
      <PieChart width={chartWidth || 320} height={chartHeight || 300}>
        <Pie
          data={data}
          dataKey='value'
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={100}
          outerRadius={126}
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
          <Label content={renderCustomizedLabel} position='center' />
        </Pie>
        <Tooltip />
        {showLegend ? (
          <Legend
            formatter={renderLegendText}
            align='left'
            wrapperStyle={{ bottom: '-5px' }}
          />
        ) : null}
      </PieChart>
    </div>
  )
}

GenericPieChart.defaultProps = {
  chartHeight: 300,
  chartWidth: 320,
  isPercentage: false,
  showLegend: false,
}

GenericPieChart.propTypes = {
  colors: array.isRequired,
  data: arrayOf(
    shape({
      name: string.isRequired,
      value: number.isRequired,
    })
  ).isRequired,
  dataLabel: string.isRequired,
  displayValue: string.isRequired,
  chartHeight: number,
  chartWidth: number,
  isPercentage: bool,
  showLegend: bool,
}

export default GenericPieChart

import { useState, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MSApi } from '../components/MSApi'
import MSLogin from '../components/MSLogin'
import GetLicensing from '../components/GetLicensing'
import Report from './Report'
import { configuration } from '../config/MSConfig'
import Spinner from '../components/Spinner'
import SelectedCustomer from './SelectedCustomer'

export const pca = new PublicClientApplication(configuration)

const PartnerCenterAssessment = () => {
  const { getCustomers, getCustomerSubscribedSkus, state } = MSApi()

  const [customers, setCustomers] = useState(state.customers)
  const [domains, setDomains] = useState(state.domains)
  const [allSecureScores, setAllSecureScores] = useState(state.allSecureScores)
  const [loading, setLoading] = useState(false)
  const [licenseLoading, setLicenseLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(false)

  const updateCache = data => {
    const currentData = JSON.parse(localStorage.getItem('onboardingState'))
    currentData.customers = data
    localStorage.setItem('onboardingState', JSON.stringify(currentData))
    return currentData
  }

  const updateCacheSingle = customer => {
    const currentData = JSON.parse(localStorage.getItem('onboardingState'))
    const customersTemp = currentData.customers.map(
      ele => (ele.id === customer.id ? customer : ele) // Return the updated customer or the original element
    )
    setCustomers(customersTemp)
    updateCache(customersTemp)
  }

  useEffect(() => {
    console.log('Change to customers detected')
  }, [customers])

  useEffect(() => {
    if (state?.customers) {
      setCustomers(state.customers)
    }

    if (state?.allSecureScores) {
      setAllSecureScores(state.allSecureScores)
    }
  }, [state])

  const getTenantLicenses = async () => {
    setLicenseLoading(true)

    const updatedCustomers = await Promise.all(
      customers?.map(async ele => {
        if (ele.readable) {
          // Create a copy of the object instead of mutating it
          const updatedEle = { ...ele, state: 1 }

          const output = await getCustomerSubscribedSkus(state.accessToken, ele)
          const licenses = (await output.json()).items

          if (licenses) {
            updatedEle.state = 2
            updatedEle.licenses = licenses
          } else {
            updatedEle.state = 3
          }

          updateCacheSingle(updatedEle)
          return updatedEle
        }
        return ele // Return unmodified if `readable` is false
      })
    )

    setCustomers(updatedCustomers)
    updateCache(updatedCustomers)
    setLicenseLoading(false)
  }

  const getCustomersRefresh = async () => {
    setLoading(true)
    await getCustomers()
    setAllSecureScores(state.allSecureScores)
    setLoading(false)
  }

  const handleSelectedCustomer = customerId => {
    setSelectedCustomer(customerId)
    window.scrollTo(0, 0)
  }

  const clearSelectedCustomer = () => {
    setSelectedCustomer(false)
    window.scrollTo(0, 0)
  }

  return (
    <div className='App'>
      <div className='container mx-auto'>
        <div className='mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8'>
          {loading && (
            <Spinner styles='mt-4' loadingText='Loading GDAP Assessment...' />
          )}

          {!loading && state?.customers && selectedCustomer && (
            <SelectedCustomer
              selectedCustomer={selectedCustomer}
              allSecureScores={allSecureScores}
              clearCustomer={clearSelectedCustomer}
            />
          )}

          {!loading && state?.customers && !selectedCustomer && (
            <>
              {/* <GetLicensing customers={setLoadingState} /> */}
              <Report
                customers={customers}
                domains={domains}
                allSecureScores={allSecureScores}
                onCustomerSelected={handleSelectedCustomer}
                getTenantLicenses={getTenantLicenses}
                loading={loading}
                licenseLoading={licenseLoading}
              />
            </>
          )}

          {!loading && !state?.customers && (
            <MSLogin onClick={getCustomersRefresh} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PartnerCenterAssessment

const Spinner = ({ styles, loadingText }) => (
  <>
    <div
      className={`loading-spinner w-8 h-8 mx-auto rounded-full box-border ${styles}`}
    />
    {loadingText && (
      <p className='text-center mt-4 text-gray-500'>{loadingText}</p>
    )}
  </>
)

export default Spinner

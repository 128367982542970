/* eslint-disable */
import { useMemo } from 'react'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import GenericPieChart from '../components/charts/GenericPieChart'
import ListTable from '../components/ListTable/ListTable'
import ReturnButton from '../components/ReturnButton'
import { allEntraIDRoles, minimumRoles } from '../config/MSConfig'
import SecureScoreAreaChart from '../components/charts/SecureScoreLineChart'
import CalculateSecureScore from '../components/CalculateSecureScore'

const SelectedCustomer = ({
  selectedCustomer,
  clearCustomer,
  allSecureScores,
}) => {
  let totalAppliedGdapRoles = 0
  const totalGdapRoles = allEntraIDRoles?.length
  let missingInforcerGdapRoles = 0
  let appliedInforcerGdapRoles = 0

  allEntraIDRoles.forEach(role => {
    role.Status = 'Not Established'
    role.Required = false

    minimumRoles.forEach(minimumRole => {
      if (role['Template ID'] === minimumRole.id) {
        role.Required = true
      }
    })
    selectedCustomer?.unifiedRoles?.forEach(customerRole => {
      if (role['Template ID'] === customerRole.roleDefinitionId) {
        role.Status = 'Ready'
        totalAppliedGdapRoles++
      }
    })
    if (role.Status === 'Ready' && role.Required) {
      appliedInforcerGdapRoles++
    }
    if (role.Status === 'Not Established' && role.Required) {
      missingInforcerGdapRoles++
    }
  })

  const tableColumns = [
    {
      header: 'Entra ID Role Name',
      accessor: 'Role',
    },
    {
      header: 'Role Description',
      accessor: 'Description',
    },
    {
      header: 'Impact',
      accessor: 'Impact',
      Cell: ({ row }) => {
        let badgeStyle = 'bg-emerald-100 text-emerald-700' // Default green badge

        if (row.original.Impact === 'High') {
          badgeStyle = 'bg-red-200 text-red-700'
        } else if (row.original.Impact === 'Medium') {
          badgeStyle = 'bg-amber-100 text-amber-700'
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            <p className='whitespace-nowrap text-sm'>{row.original.Impact}</p>
          </span>
        )
      },
    },
    {
      header: 'Status',
      accessor: 'Status',
      Cell: ({ row }) => {
        let badgeStyle = 'bg-emerald-100 text-emerald-700' // Default green badge

        if (row.original.Status === 'Not Established') {
          if (row.original.Required) {
            badgeStyle = 'bg-red-200 text-red-700'
          } else {
            badgeStyle = 'bg-amber-100 text-amber-700'
          }
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            <p className='whitespace-nowrap text-sm'>{row.original.Status}</p>
          </span>
        )
      },
    },
    {
      header: 'Required',
      accessor: 'Required',
      Cell: ({ row }) => {
        let badgeStyle = 'bg-amber-100 text-amber-700'
        let message = 'Not Required'
        if (row.original.Required) {
          badgeStyle = 'bg-emerald-100 text-emerald-700' // Default green badge
          message = 'inforcer'
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            <p className='whitespace-nowrap text-sm'>{message}</p>
          </span>
        )
      },
    },
  ]

  let inforcerReady = false
  let message = 'Not Ready'
  if (missingInforcerGdapRoles === 0) {
    inforcerReady = true
    message = 'Ready'
  }

  let GDAPRenewal = 'Disabled'
  if (selectedCustomer.autoExtendDuration === 'P180D') {
    GDAPRenewal = 'Enabled'
  }

  // For some reason this returns a couple of null items if the last login was a few days ago.
  // Handling via the .filter below to remove null items for now :(
  const calculatedSecureScores = CalculateSecureScore({
    allSecureScores,
    selectedCustomer,
  })
  const hasScores = calculatedSecureScores.length > 1
  let score = 'Unknown'
  if (hasScores) {
    score = `${calculatedSecureScores.filter(x => x != null).slice(-1)[0]?.score}%`
  }

  const customerDetailColumns = [
    {
      header: 'Property',
      accessor: 'Property',
    },
    {
      header: 'Value',
      accessor: 'Value',
      Cell: ({ row }) => {
        if (row.original.Property === 'inforcer Onboarding Status') {
          let badgeStyle = 'bg-red-200 text-red-700'
          if (row.original.Value === 'Ready') {
            badgeStyle = 'bg-emerald-100 text-emerald-700' // Default green badge
          }
          return (
            <span
              className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
            >
              <p className='whitespace-nowrap text-sm'>{message}</p>
            </span>
          )
        }
        if (row.original.Property === 'GDAP Renewal') {
          let badgeStyle = 'bg-red-200 text-red-700'
          if (GDAPRenewal === 'Enabled') {
            badgeStyle = 'bg-emerald-100 text-emerald-700' // Default green badge
          }
          return (
            <span
              className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
            >
              <p className='whitespace-nowrap text-sm'>{GDAPRenewal}</p>
            </span>
          )
        }
        return row.original.Value
      },
    },
  ]

  const customerDetail = [
    {
      Property: 'Customer Name',
      Value: selectedCustomer.companyProfile.companyName,
    },
    {
      Property: 'Tenant Id',
      Value: selectedCustomer.companyProfile.tenantId,
    },
    {
      Property: 'inforcer Onboarding Status',
      Value: message,
    },
    {
      Property: 'Secure Score',
      Value: score,
    },
    {
      Property: 'GDAP Renewal',
      Value: score,
    },
  ]

  const gdapChartColors = ['#a1a1aa', '#171B3A', '#44aa44', '#aa4444']

  const gdapChartData = useMemo(() => {
    const totalGdapRolesDiff = totalGdapRoles - totalAppliedGdapRoles
    const otherGdapRoles = totalAppliedGdapRoles - appliedInforcerGdapRoles

    return [
      { name: 'Available GDAP Roles', value: totalGdapRolesDiff },
      {
        name: 'Other GDAP Roles',
        value: otherGdapRoles,
      },
      { name: 'Applied inforcer Roles', value: appliedInforcerGdapRoles },
      { name: 'Missing inforcer Roles', value: missingInforcerGdapRoles },
    ]
  }, [
    totalGdapRoles,
    totalAppliedGdapRoles,
    missingInforcerGdapRoles,
    appliedInforcerGdapRoles,
  ])

  return (
    <>
      <div className='flex justify-between mb-8 max-md:flex-col'>
        <div className='flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4'>
          <ListTable
            uniqueKey='id'
            columns={customerDetailColumns}
            data={customerDetail ?? []}
            sorting={[
              {
                id: 'Property',
                desc: false,
              },
            ]}
            enablePagination={false}
            renderTopToolbar={false}
            enableGlobalFilter={false}
          />
        </div>
        <div className='flex-1 px-5 py-10 ml-8 border border-gray-300 rounded-lg shadow-sm bg-white max-md:ml-0'>
          <GenericPieChart
            colors={gdapChartColors}
            data={gdapChartData}
            displayValue={missingInforcerGdapRoles}
            dataLabel='Missing Roles'
            chartHeight={380}
            showLegend
          />
        </div>
      </div>
      <div>
        {hasScores === true ? (
          <SecureScoreAreaChart secureScore={calculatedSecureScores} />
        ) : null}
      </div>
      <ListTable
        uniqueKey='id'
        columns={tableColumns}
        data={allEntraIDRoles ?? []}
        sorting={[
          {
            id: 'Required',
            desc: true,
          },
        ]}
        renderTopToolbar={({ table }) => (
          <div className='flex justify-between items-center py-2'>
            <MRT_GlobalFilterTextField table={table} />

            <div className='flex gap-3'>
              <ReturnButton onClick={clearCustomer} />
            </div>
          </div>
        )}
      />
      <button
        onClick={clearCustomer}
        className='text-sm underline cursor-pointer'
      >
        Go Back
      </button>
    </>
  )
}

export default SelectedCustomer

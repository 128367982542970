import { useState, useEffect, useRef, useMemo } from 'react'
/* eslint-disable camelcase */
import { MRT_GlobalFilterTextField } from 'material-react-table'
/* eslint-enable camelcase */
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  minimumRoles,
  // minimumLicensingRoles,
  globalAdminRole,
} from '../config/MSConfig'
// import Modal from '../components/Modal'
import CreateTableColumns from '../components/ListTable/CreateTableColumns'
import ListTable from '../components/ListTable/ListTable'
import LicenseBarChart from '../components/charts/LicenseBarChart'
import PDFExportButton from '../components/PDFExportButton'
import Spinner from '../components/Spinner'
import {
  GreenBadge,
  YellowBadge,
  RedBadge,
} from '../components/ListTable/StatusBadge'
import CalculateAverageSecureScore from '../components/CalculateAverageSecureScore'
import AverageSecureScoreAreaChart from '../components/charts/AverageSecureScoreLineChart'
import GenericPieChart from '../components/charts/GenericPieChart'

const Report = ({
  customers,
  onCustomerSelected,
  getTenantLicenses,
  loading,
  licenseLoading,
  domains,
  allSecureScores,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState(0)

  const chartRef = useRef(null)

  const handleSelectedCustomer = customer => {
    onCustomerSelected(customer)
  }

  const [customerCount, setCustomerCount] = useState(0)
  const [gdapEnabledCustomers, setGdapEnabledCustomers] = useState(0)
  const [gdapReadyCustomers, setGdapReadyCustomers] = useState(0)
  const [tenantsWithBusinessE3, setTenantsWithBusinessE3] = useState(0)
  const [tenantsWithBusinessE5, setTenantsWithBusinessE5] = useState(0)
  const [tenantsWithBusinessStandard, setTenantsWithBusinessStandard] =
    useState(0)
  const [tenantsWithBusinessPremium, setTenantsWithBusinessPremium] =
    useState(0)
  const [customerTable, setCustomerTable] = useState('')
  const [averageSecureScore, setAverageSecureScore] = useState(0)

  useEffect(() => {
    const processCustomers = async () => {
      if (customers) {
        let gdapEnabledCount = 0
        let gdapReadyCount = 0
        let businessPremiumCount = 0
        let totalSecureScorePercentage = 0
        let totalSecureScoreTenants = 0

        setCustomerCount(customers?.length)

        const licenseTier = {
          'Microsoft 365 Business Premium': 12,
          'M365 E5': 11,
          'M365 F5': 10,
          'M365 A5': 9,
          'Microsoft 365 Business Standard': 8,
          'M365 E3': 7,
          'M365 F3': 6,
          'M365 A3': 5,
          'Microsoft 365 Business Basic': 4,
          'M365 E1': 3,
          'M365 F1': 2,
          'M365 A1': 1,
          Other: 0, // Default for unknown licenses
        }

        const updatedCustomerTable = customers.map(ele => {
          const missingRoles = []
          let missingRolesHTML = <p>Loading...</p>
          let secureScorePercentage = 0

          allSecureScores?.forEach(secureScore => {
            if (secureScore.tenantId === ele?.id) {
              secureScorePercentage =
                (secureScore.currentScore / secureScore.maxScore) * 100
            }
          })
          // License Counting Logic
          const licenseCount = {}
          let primaryLicense = 'None'

          ele?.licenses?.forEach(license => {
            const licenseName = license.productSku?.name || 'Other'
            licenseCount[licenseName] = (licenseCount[licenseName] || 0) + 1
          })

          let maxCount = 0
          Object.keys(licenseCount).forEach(licenseName => {
            const count = licenseCount[licenseName]
            if (
              count > maxCount ||
              (count === maxCount &&
                licenseTier[licenseName] > licenseTier[primaryLicense])
            ) {
              maxCount = count
              primaryLicense = licenseName
            }
          })

          // Assign Primary License
          ele.primaryLicense = primaryLicense // eslint-disable-line no-param-reassign

          if (secureScorePercentage > 0) {
            totalSecureScoreTenants++
            totalSecureScorePercentage += secureScorePercentage
          }

          if (totalSecureScoreTenants > 0) {
            const calculatedSecureScore =
              totalSecureScorePercentage / totalSecureScoreTenants
            setAverageSecureScore(calculatedSecureScore)
          } else {
            setAverageSecureScore(0) // Default to 0 if no tenants are valid
          }

          // Increment GDAP Enabled count
          if (ele?.unifiedRoles?.length > 0) gdapEnabledCount += 1

          // Check for Global Admin role
          const hasGlobalAdmin = ele?.unifiedRoles?.some(
            customerRole => globalAdminRole.id === customerRole.roleDefinitionId
          )

          // Check for Business Premium license
          const hasBusinessPremium = ele?.licenses?.some(
            license =>
              license.productSku.name === 'Microsoft 365 Business Premium' ||
              license.productSku?.sku === 'SBP'
          )
          if (hasBusinessPremium) businessPremiumCount += 1

          if (!hasGlobalAdmin) {
            minimumRoles.forEach(role => {
              const validRole = ele?.unifiedRoles?.some(
                customerRole => role.id === customerRole.roleDefinitionId
              )

              if (!validRole) missingRoles.push(role.displayName)
            })

            if (missingRoles?.length === 0) {
              gdapReadyCount += 1
              ele.readable = true // eslint-disable-line no-param-reassign
              missingRolesHTML = <p>There are no missing roles!</p>
            } else {
              missingRolesHTML = (
                <div className='mx-auto max-w-3xl text-center'>
                  <h1 className='mb-2 mt-0 text-3xl font-medium leading-tight text-primary'>
                    Missing GDAP Roles
                  </h1>
                  <ul>
                    {missingRoles.map((role, index) => (
                      <li key={`${ele?.id}-${index}`}>{role}</li>
                    ))}
                  </ul>
                </div>
              )
            }
          } else {
            gdapReadyCount += 1
            ele.readable = true // eslint-disable-line no-param-reassign
            missingRolesHTML = (
              <p>
                This customer has the <b>Global Administrator</b> role assigned.
              </p>
            )
          }

          let missingRoleBadge

          if (ele?.unifiedRoles?.length > 0) {
            if (missingRoles?.length === 0) {
              missingRoleBadge = <GreenBadge />
            } else {
              missingRoleBadge = (
                <YellowBadge missingRoles={missingRoles.length} />
              )
            }
          } else {
            missingRoleBadge = <RedBadge />
          }

          /* eslint-disable no-param-reassign */
          ele.missingRoles = missingRoles
          ele.secureScorePercentage = secureScorePercentage.toFixed(1)
          ele.missingRoleBadge = missingRoleBadge
          ele.missingRolesHTML = missingRolesHTML
          /* eslint-enable no-param-reassign */

          return ele
        })

        // Set final counts in state once processing is complete
        setGdapEnabledCustomers(gdapEnabledCount)
        setGdapReadyCustomers(gdapReadyCount)
        setTenantsWithBusinessPremium(businessPremiumCount)
        setCustomerTable(updatedCustomerTable)
      }
    }

    processCustomers()
  }, [customers])

  const calculateDaysRemaining = endDateTime => {
    if (!endDateTime) return 'N/A'
    const endDate = new Date(endDateTime)
    const currentDate = new Date()
    const timeDifference = endDate - currentDate
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return daysRemaining >= 0 ? daysRemaining : 'Expired'
  }

  const licensesRequested = customers?.some(
    customer => customer.licenses && customer.licenses.length > 0
  )

  const formatAutoRenewalStatus = (status, id, asText = false) => {
    if (asText) {
      // Return filterable plain text
      return status === 'P180D' ? 'On' : 'Off'
    }

    // Return JSX for rendering
    return status === 'P180D' ? (
      <span className='inline-flex items-center justify-center rounded-full bg-green-200 px-2.5 py-0.5 text-green-700'>
        <p className='whitespace-nowrap text-sm'>On</p>
      </span>
    ) : (
      <span className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700'>
        <p className='whitespace-nowrap text-sm'>Off</p>
      </span>
    )
  }

  const calculateUnconsumedLicenses = licenses => {
    if (!licenses || licenses.length === 0) {
      return 0 // No licenses available
    }

    let totalConsumedUnits = 0
    let totalActiveUnits = 0

    licenses.forEach(license => {
      const licenseName = license.productSku.name
      if (
        licenseName.includes('Microsoft 365 Business') ||
        licenseName.includes('Microsoft 365 E') ||
        licenseName.includes('Microsoft 365 F')
      ) {
        // This is a primary license
        totalConsumedUnits += license.consumedUnits
        totalActiveUnits += license.activeUnits
      }
    })

    const difference = totalActiveUnits - totalConsumedUnits
    return difference < 0 ? 0 : difference
  }

  // Helper function to flatten a single object

  const flattenObject = (obj, parent = '', res = {}) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = parent ? `${parent}.${key}` : key

        // Handle arrays of objects like unifiedRoles
        if (Array.isArray(obj[key])) {
          if (obj[key].length > 0 && typeof obj[key][0] === 'object') {
            // If the array contains objects, extract the roleDefinitionId from each object
            res[propName] = obj[key]
              .map(item => item.roleDefinitionId || JSON.stringify(item))
              .join(', ')
          } else {
            // If the array contains non-object items, join them as a comma-separated string
            res[propName] = obj[key].join(', ')
          }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          flattenObject(obj[key], propName, res) // Recursively flatten nested objects
        } else {
          res[propName] = obj[key] // Assign other values as-is
        }
      }
    }
    return res
  }

  const tenantReportData =
    customers?.map(data => {
      const GdapState = data => {
        let state = 'No Relationship'
        if (data?.unifiedRoles?.length > 0) {
          if (data.missingRoles?.length === 0) {
            state = 'inforcer Ready'
          } else {
            state = `Missing ${data.missingRoles?.length} Roles`
          }
        }
        return state
      }

      return {
        tenantId: data?.companyProfile.tenantId ?? '',
        domain: data?.companyProfile.domain ?? '',
        companyName: data?.companyProfile.companyName ?? '',
        gdapDaysRemaining: calculateDaysRemaining(data.endDateTime) ?? '',
        gdapStatus: GdapState(data) ?? '',
        missingRoles: data.missingRoles ?? '',
        autoRenewalStatus:
          formatAutoRenewalStatus(data.autoExtendDuration) ?? '',
        unconsumedLicenses: calculateUnconsumedLicenses(data.licenses) ?? '',
      }
    }) ?? []

  const clearLocalOnboardingState = () => {
    localStorage.removeItem('onboardingState')
    window.location.reload()
  }

  const allTenantsLicensesEmpty =
    tenantsWithBusinessStandard === 0 &&
    tenantsWithBusinessPremium === 0 &&
    tenantsWithBusinessE3 === 0 &&
    tenantsWithBusinessE5 === 0

  // Check if any primaryLicense is not 'None'
  const hasValidPrimaryLicense = customers?.some(customer =>
    customer.licenses?.every(license => {
      const licenseName = license.productSku?.name
      return licenseName !== 'None'
    })
  )
  const tableColumns = CreateTableColumns({
    handleSelectedCustomer,
    calculateDaysRemaining,
    formatAutoRenewalStatus,
    licenseDataExists: hasValidPrimaryLicense,
  })

  // Config tenant chart data
  const tenantChartData = useMemo(() => {
    const nonGdapCustomers = customerCount - gdapEnabledCustomers
    const gdapReadyButNotEnabled = gdapEnabledCustomers - gdapReadyCustomers

    return [
      { name: 'GDAP Enabled, inforcer Ready', value: gdapEnabledCustomers },
      {
        name: 'GDAP Enabled, not inforcer Ready',
        value: gdapReadyButNotEnabled,
      },
      { name: 'Non-GDAP Enabled Customers', value: nonGdapCustomers },
    ]
  }, [customerCount, gdapEnabledCustomers, gdapReadyCustomers])

  const tenantChartColors = ['#178BDB', '#171B3A', '#a1a1aa']

  // Sanitise/config data for secure score pie chart
  const cleanData = data =>
    data.filter(entry => entry && typeof entry === 'object')

  const averageSecureScoreChartData = cleanData(
    CalculateAverageSecureScore(allSecureScores)
  )

  const averageSecureScorePercentage = averageSecureScore.toFixed(1)

  const secureScoreChartData = useMemo(
    () => [
      {
        name: 'Average Secure Score',
        value: Math.round(averageSecureScore * 10) / 10,
      },
      {
        name: 'Remaining Secure Score',
        value: 100.0 - averageSecureScorePercentage,
      },
    ],
    [averageSecureScorePercentage]
  )

  const secureScoreChartColors = ['#178BDB', '#a1a1aa']

  return (
    <div className='Report'>
      <div ref={chartRef}>
        <div className='flex justify-between mb-4 max-md:flex-col max-md:mb-0'>
          <div className='flex-2 mr-4 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mr-0 max-md:mb-4'>
            <GenericPieChart
              colors={tenantChartColors}
              data={tenantChartData}
              displayValue={customerCount}
              dataLabel='Tenants'
            />
          </div>
          <div className='flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4'>
            {allTenantsLicensesEmpty ? (
              <div className='flex justify-center items-center flex-col h-[100%]'>
                <p className='text-gray-500 mb-6'>
                  Request data to see breakdown of tenants licenses.
                </p>
                <button
                  type='button'
                  className='mx-auto inline-flex items-center gap-2 rounded border border-inforcer-navy bg-inforcer-navy px-20 py-3 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy cursor-pointer'
                  onClick={getTenantLicenses}
                >
                  <span className='text-lg font-medium'>
                    Start Licensing Report
                  </span>

                  <span className='text-xl font-large'>
                    <FontAwesomeIcon icon='fa-regular fa-id-card' />
                  </span>
                </button>
              </div>
            ) : loading || licenseLoading ? (
              <Spinner
                styles='mt-[100px]'
                loadingText='Fetching tenant licenses...'
              />
            ) : (
              <LicenseBarChart
                customerCount={customerCount}
                tenantsWithBusinessStandard={tenantsWithBusinessStandard}
                tenantsWithBusinessPremium={tenantsWithBusinessPremium}
                tenantsWithBusinessE3={tenantsWithBusinessE3}
                tenantsWithBusinessE5={tenantsWithBusinessE5}
              />
            )}
          </div>
        </div>
        <div className='flex justify-between mb-4 max-md:flex-col'>
          <div className='flex-2 mr-4 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mr-0 max-md:mb-4'>
            <GenericPieChart
              colors={secureScoreChartColors}
              data={secureScoreChartData}
              displayValue={averageSecureScorePercentage}
              isPercentage
              dataLabel='Average Secure Score'
            />
          </div>
          <div className='flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4'>
            <AverageSecureScoreAreaChart
              averageSecureScore={averageSecureScoreChartData}
            />
          </div>
        </div>
      </div>

      <ListTable
        uniqueKey='id'
        columns={tableColumns}
        data={customers ?? []}
        enableGlobalFilter
        loading={loading || licenseLoading}
        renderTopToolbar={({ table }) => (
          <div className='flex justify-between items-center py-2'>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <MRT_GlobalFilterTextField table={table} />
            {/* eslint-enable-next-line react/jsx-pascal-case */}
            <div className='flex gap-3'>
              <PDFExportButton tenants={tenantReportData} chartRef={chartRef} />
              <CSVLink
                className={`rounded border text-sm border-inforcer-cyan bg-inforcer-cyan px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan cursor-pointer ${
                  customers?.length === 0
                    ? 'pointer-events-none bg-gray-500 opacity-50'
                    : 'bg-inforcer-cyan'
                }`}
                data={tenantReportData}
                filename='inforcer_gdap_assessment.csv'
              >
                <FontAwesomeIcon className='mr-2' icon={faDownload} />
                Export to CSV
              </CSVLink>
            </div>
          </div>
        )}
      />
      <button
        type='button'
        onClick={clearLocalOnboardingState}
        className='text-sm underline cursor-pointer'
      >
        Clear data
      </button>
    </div>
  )
}

export default Report
